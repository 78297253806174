import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MyMap from "../components/map"

const ContactPage = ({data}) => (
  <Layout className="w-full lg:w-3/4 mx-auto px-8 lg:pr-16">
    <SEO title="Contact" />
    <h1>Contact</h1>
    <p>Traub stuc heeft een ambitieus beeld van de toekomst. Wij vinden dat iedereen een kans moet krijgen om een mooi vak te leren om in zijn eigen levensonderhoud te voorzien.</p>

    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      <div>
        <h3>Adres</h3>
        <ul className="mb-4">
          <li>{data.contact.address_name}</li>
          <li>{data.contact.street}</li>
          <li>{data.contact.zipcode} {data.contact.city}</li>
        </ul>


        <h3>Telefoon</h3>
        <ul className="mb-4">
          <li><a href={`tel:${data.contact.phone.data}`}>{data.contact.phone.visual}</a></li>
        </ul>

        <h3>Mobiel</h3>
        <ul className="mb-4">
          <li><a href={`tel:${data.contact.peet.data}`}>Peet: {data.contact.peet.visual}</a></li>
          <li><a href={`tel:${data.contact.michel.data}`}>Michel: {data.contact.michel.visual}</a></li>
        </ul>

        <h3>Email</h3>
        <ul className="mb-4">
          <li><a href={`mailto:${data.contact.email}`}>{data.contact.email}</a></li>
        </ul>
      </div>
      <div>
        <MyMap className="w-full h-64 lg:h-full" center={data.contact.coordinates} zoom={data.contact.zoom} />
      </div>
    </div>
  </Layout>
)

export default ContactPage

export const query =  graphql`
{
  contact: dataYaml {
    coordinates
    zoom
    street
    zipcode
    city
    email
    address_name
    michel {
      visual
      data
    }
    peet {
      visual
      data
    }
    phone {
      visual
      data
    }
  }
}
`