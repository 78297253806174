import React, { Component } from 'react'
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'

const MyMap = (options) => {

    if (typeof window !== 'undefined') {
      return (
        <Map {...options}>
          <TileLayer
            url="https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=t3a8mfFpK9Z3ruMElASk"
            tileSize={512}
            zoomOffset={-1}
            attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
            />
            <Marker position={options.center}></Marker>
        </Map>
      )
    }
    return null
}

export default MyMap;